import { forEach, keys, pickBy, some } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Modal, Portal, Select } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { ISelectOption } from "../../../components/Select";
import {
    useEngineerPropertyCategories,
    useLandlordPreferences,
    useUpdateEngineerPropertyCategories,
} from "../../../utils/api/landlords";
import { IPropertyCategory } from "../../../utils/api/misc";

const PropertyCategories = ({
    engineerId,
    hide,
    propertyCategories,
}: IPropertyCategoriesProps) => {
    const { t } = useTranslation();
    const [landlordId, setLandlordId] = useState(0);
    const landlordPreferences = useLandlordPreferences();
    const engineerPropertyCategories =
        useEngineerPropertyCategories(engineerId);
    const updateEngineerPropertyCategories =
        useUpdateEngineerPropertyCategories();
    const [categories, setCategories] = useState<IEngineerCategories>({});

    const landlords = useMemo<ISelectOption[]>(() => {
        if (landlordPreferences.value) {
            return landlordPreferences.value.map((entry) => ({
                label: entry.name,
                value: entry.id.toString(),
            }));
        }

        return [];
    }, [landlordPreferences.value]);

    useEffect(() => {
        const dictionary: IEngineerCategories = {};

        if (engineerPropertyCategories.value) {
            forEach(propertyCategories, (category) => {
                if (category.name !== "all") {
                    const checked = some(
                        engineerPropertyCategories.value,
                        (propertyCategory) =>
                            propertyCategory.propertyCategoryId ===
                                category.id &&
                            propertyCategory.landlordId === landlordId,
                    );

                    dictionary[category.id.toString()] = {
                        name: category.displayName,
                        checked,
                    };
                }
            });
        }

        setCategories(dictionary);
    }, [engineerPropertyCategories.value, landlordId, propertyCategories]);

    const updateLandlord = (id: string) => {
        setLandlordId(Number(id));
    };

    const updateChecked = (checked: boolean, value: string) => {
        const tempCategories = { ...categories };

        tempCategories[value].checked = checked;

        setCategories(tempCategories);
    };

    // TODO: Use form with validation.
    const saveEngineerPropertyCategories = () => {
        const selectedCategories = keys(
            pickBy(categories, (category) => category.checked),
        ).map((category) => Number(category));

        updateEngineerPropertyCategories
            .updateEngineerPropertyCategories(
                engineerId,
                [landlordId],
                selectedCategories,
            )
            .subscribe();

        hide();
    };

    return (
        <Portal>
            <Modal hide={hide} title={t("Select property categories")}>
                <ModalBody>
                    <Select
                        label={t("Select a Landlord")}
                        options={landlords}
                        allowEmpty={true}
                        value={landlordId.toString()}
                        onChange={updateLandlord}
                    />

                    {engineerPropertyCategories.loaded &&
                        landlordId !== 0 &&
                        keys(categories).map((id) => (
                            <Checkbox
                                key={id}
                                value={id}
                                checked={categories[id].checked}
                                onChange={updateChecked}
                            >
                                {categories[id].name}
                            </Checkbox>
                        ))}
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="primary"
                        onClick={saveEngineerPropertyCategories}
                    >
                        {t("Ok")}
                    </Button>
                </ModalFooter>
            </Modal>
        </Portal>
    );
};

interface IPropertyCategoriesProps {
    engineerId: number;
    hide: () => void;
    propertyCategories: IPropertyCategory[];
}

interface IEngineerCategories {
    [key: string]: { name: string; checked: boolean };
}

export default PropertyCategories;
