import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, InputField } from "../../../components";
import { useFilter } from "../../../hooks";
import { ILandlordEngineer } from "../../../utils/api/landlords";
import { findScrollableAncestor } from "../../../utils/scroll";
import EngineerCheckbox from "../EngineerCheckbox";

const EngineersFilter = ({
    engineers,
    isShowAllChecked,
    selectedIds,
    onShowAllCheck,
    onEngineerCheck,
    jobsCount,
}: IEngineersFilterProps) => {
    const { t } = useTranslation();

    const filter = useFilter(engineers, (engineer) => [engineer.name]);

    const inputRef = useRef<HTMLInputElement>(null);
    const scrollableAncestor = useMemo(
        () => findScrollableAncestor(inputRef.current),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inputRef.current],
    );

    return (
        <>
            <InputField
                placeholder={t("Filter")}
                value={filter.search}
                onChange={filter.handleSearchChange}
                setRef={inputRef}
            />

            <Checkbox onChange={onShowAllCheck} checked={isShowAllChecked}>
                <strong>{t("Show All")}</strong>
            </Checkbox>

            {filter.filteredRecords.map((engineer) => (
                <EngineerCheckbox
                    key={engineer.id}
                    onChange={onEngineerCheck}
                    engineer={engineer}
                    scrollableAncestor={scrollableAncestor}
                    selectedIds={selectedIds}
                    jobsCount={jobsCount[engineer.id.toString()]}
                />
            ))}
        </>
    );
};

interface IEngineersFilterProps {
    engineers: ILandlordEngineer[];
    isShowAllChecked: boolean;
    selectedIds: number[];
    onShowAllCheck: (checked: boolean) => void;
    onEngineerCheck: (checked: boolean, id: number) => void;
    jobsCount: { [key: string]: number };
}

export default EngineersFilter;
