import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDueDateDisplay, IManualUploadFormProps, IPropertySelector } from ".";
import {
    Alert,
    Button,
    Card,
    Checkbox,
    ColourPill,
    Form,
    Grid,
    GridColumn,
    InputDatePicker,
    InputField,
    MultilineInputField,
    SearchableSelect,
    SelectFilter,
} from "..";
import UnsafeAppliances from "../../pages/AddManualUpload/UnsafeAppliances";
import { useEngineers } from "../../utils/api/landlords";
import {
    useFilteredPaperworkTypes,
    useManualUploadReasons,
} from "../../utils/api/misc";
import { IDueDate, useProperties } from "../../utils/api/properties";
import {
    getDifferenceInDays,
    getNow,
    getToday,
    toDateString,
} from "../../utils/dates";
import {
    isRequired,
    isTrue,
    useValidateField,
    validateForm,
} from "../../utils/validation";
import FileUpload from "../FileUpload";
import Loading from "../Loading";
import Select, { ISelectOption } from "../Select";
import styles from "./ManualUploadForm.module.scss";

const ManualUploadForm = ({
    cardTitle,
    dateFieldLabel,
    showPaperworkTypes,
    showCommentsField,
    showUnsafeAppliances,
    isLoading,
    error,
    successMessage,
    onSubmit,
}: IManualUploadFormProps) => {
    const { t } = useTranslation();

    const getFontColor = useCallback(
        (dueInDays: number) =>
            dueInDays > 30
                ? styles.green
                : dueInDays >= 0
                ? styles.orange
                : styles.red,
        [],
    );

    const {
        records: properties,
        loaded: propertiesLoaded,
        search: propertiesSearch,
        applySearch: propertiesApplySearch,
        currentPage: propertiesCurrentPage,
        totalPages: propertiesTotalPages,
        goToNextPage: propertiesGoToNextPage,
    } = useProperties({
        sortProperty: "addressString",
        sortDirection: "asc",
    });
    const propertyOptions = useMemo(() => {
        return properties
            .map<IPropertySelector>((prop) => {
                // 1. Create the due days displays, ordered by the due in days (ascending)
                const dates = prop.nextServiceDueDates
                    .map<IDueDateDisplay>((date) => {
                        const dueInDays = Math.floor(
                            date.date !== null
                                ? getDifferenceInDays(
                                      getToday(),
                                      new Date(date.date),
                                  )
                                : 365,
                        );

                        return {
                            dueInDays,
                            fuelType: {
                                id: date.fuelTypeId,
                                displayName: date.fuelTypeName,
                                color: date.color,
                            },
                            date: date.date,
                            fontColor: getFontColor(dueInDays),
                        };
                    })
                    .sort((a, b) => a.dueInDays - b.dueInDays);
                // 2. Create the property model
                return {
                    id: prop.id,
                    addressString: prop.addressString,
                    colour:
                        (dates.length && getFontColor(dates[0].dueInDays)) ||
                        styles.green,
                    dates,
                };
            })
            .map((prop) => {
                return {
                    label: (
                        <div
                            className={`${styles.propertyDropdownItem} ${prop.colour}`}
                        >
                            <p>{prop.addressString}</p>

                            <Grid>
                                {prop.dates.map((date) => (
                                    <Fragment key={date.fuelType.id}>
                                        <GridColumn
                                            size="twentyPercent"
                                            cssRules={{
                                                fontSize: "0.75rem",
                                                padding: "5px",
                                            }}
                                        >
                                            <span
                                                className={
                                                    styles.propertyCategory
                                                }
                                            >
                                                <ColourPill
                                                    customColour={
                                                        date.fuelType.color
                                                    }
                                                    value={t(
                                                        date.fuelType
                                                            .displayName,
                                                    )}
                                                    fullWidth={true}
                                                />
                                            </span>
                                        </GridColumn>

                                        <GridColumn
                                            size="thirtyPercent"
                                            cssRules={{
                                                fontSize: "0.75rem",
                                                padding: "5px",
                                            }}
                                        >
                                            {date.dueInDays > 0 ? (
                                                <>
                                                    {t("Due in")}&nbsp;
                                                    <span
                                                        className={`${styles.dueDate} ${date.fontColor}`}
                                                    >
                                                        {date.dueInDays}
                                                    </span>
                                                    &nbsp;
                                                    {t("daysCount", {
                                                        count: date.dueInDays,
                                                    })}
                                                    &nbsp;
                                                    {date.date &&
                                                        `(${toDateString(
                                                            new Date(date.date),
                                                        )})`}
                                                </>
                                            ) : (
                                                <span
                                                    className={`${styles.dueDate} ${prop.colour}`}
                                                >
                                                    {t("Overdue")}
                                                </span>
                                            )}
                                        </GridColumn>
                                    </Fragment>
                                ))}
                            </Grid>
                        </div>
                    ),
                    value: prop.id.toString(),
                };
            });
    }, [getFontColor, properties, t]);

    const {
        records: engineers,
        loaded: engineersLoaded,
        goToNextPage: engineersGoToNextPage,
        totalPages: engineersTotalPages,
        currentPage: engineersCurrentPage,
        search: engineersSearch,
        applySearch: engineersApplySearch,
    } = useEngineers({
        sortProperty: "name",
        sortDirection: "asc",
    });
    const enginnerOptions = useMemo<ISelectOption[]>(
        () =>
            engineers.map((engineer) => ({
                value: engineer.id.toString(),
                label: engineer.name,
            })),
        [engineers],
    );

    const [complianceTypeFilter, setComplianceTypeFilter] = useState("");
    const [propertyDueDates, setPropertyDueDates] = useState<IDueDate[]>([]);

    const complianceTypeOptions = useMemo(() => {
        return propertyDueDates
            .filter(
                (dueDate) =>
                    !complianceTypeFilter ||
                    dueDate.fuelTypeName
                        .toLocaleLowerCase()
                        .includes(complianceTypeFilter.toLocaleLowerCase()),
            )
            .sort(
                (a, b) =>
                    (a.date !== null
                        ? new Date(a.date)
                        : getToday()
                    ).getTime() -
                    (b.date !== null ? new Date(b.date) : getToday()).getTime(),
            )
            .map((dueDate) => {
                const date =
                    dueDate.date !== null ? new Date(dueDate.date) : getToday();
                const days = Math.floor(getDifferenceInDays(getToday(), date));

                return {
                    label: (
                        <div className={styles.complianceTypeDropdownItem}>
                            <Grid>
                                <GridColumn size="thirtyPercent">
                                    <ColourPill
                                        fullWidth={true}
                                        textCenter={true}
                                        value={dueDate.fuelTypeName}
                                        customColour={dueDate.color}
                                    />
                                </GridColumn>
                                <GridColumn size="seventyPercent">
                                    {days > 0 ? (
                                        <>
                                            {t("Due in")}&nbsp;
                                            <span
                                                className={`${
                                                    styles.dueDate
                                                } ${getFontColor(days)}`}
                                            >
                                                {days}
                                            </span>
                                            &nbsp;
                                            {t("daysCount", {
                                                count: days,
                                            })}
                                        </>
                                    ) : (
                                        <span
                                            className={`${
                                                styles.dueDate
                                            } ${getFontColor(days)}`}
                                        >
                                            {t("Overdue")}
                                        </span>
                                    )}
                                    &nbsp;({toDateString(date)})
                                </GridColumn>
                            </Grid>
                        </div>
                    ),
                    value: dueDate.fuelTypeId.toString(),
                };
            });
    }, [complianceTypeFilter, getFontColor, propertyDueDates, t]);

    const { value: manualUploadReasons } = useManualUploadReasons();
    const manualUploadReasonsOptions = useMemo<ISelectOption[]>(
        () =>
            manualUploadReasons.map((reason) => ({
                label: t(reason.description),
                value: reason.id.toString(),
            })),
        [manualUploadReasons, t],
    );

    const otherReasonId = useMemo(
        () =>
            manualUploadReasons
                .find((r) => r.description === "Other")
                ?.id.toString(),
        [manualUploadReasons],
    );

    const [propertyUprn, setPropertyUprn] = useState("");
    const [propertyId, setPropertyId] = useState("");
    const updatePropertyId = useCallback(
        (id: string) => {
            const property = properties.find((p) => p.id.toString() === id);

            setComplianceTypeId("");

            if (property) {
                setPropertyId(id);
                setLandlordId(property.landlord.id.toString());
                setPropertyDueDates(property.nextServiceDueDates);
                setPropertyUprn(property.uprn);
            } else {
                setPropertyId("");
                setLandlordId("");
                setPropertyDueDates([]);
                setPropertyUprn("");
            }
        },
        [properties],
    );

    const [isCapped, isServiceDue] = useMemo(() => {
        let capped = false;
        let serviceDue = false;

        const property = properties.find(
            (record) => record.id === Number(propertyId),
        );

        if (property !== undefined) {
            capped = property.isCapped;

            const nextServiceDate = property.nextServiceDueDates.find(
                (s) => s.fuelTypeId === 1,
            );

            if (
                nextServiceDate !== undefined &&
                nextServiceDate.date !== null
            ) {
                serviceDue =
                    Math.floor(
                        getDifferenceInDays(
                            getToday(),
                            new Date(nextServiceDate.date),
                        ),
                    ) <= 60;
            }
        }

        return [capped, serviceDue];
    }, [properties, propertyId]);

    const customPropertyRender = useCallback(
        (value: string) => {
            const property = properties.find(
                (prop) => prop.id.toString() === value,
            );

            return property && property.addressString;
        },
        [properties],
    );

    const [landlordId, setLandlordId] = useState("");
    const [complianceTypeId, setComplianceTypeId] = useState("");
    const [engineerId, setEngineerId] = useState("");
    const [jobType, setJobType] = useState("");
    const [jobDate, setJobDate] = useState(getNow());
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [comments, setComments] = useState("");
    const [resetServiceDate, setResetServiceDate] = useState(false);
    const [safeApplianceIds, setSafeApplianceIds] = useState<string[]>([]);

    const [uploadReasonId, setUploadReasonId] = useState("");
    const [otherReason, setOtherReason] = useState("");
    const updateUploadReasonId = useCallback((id: string) => {
        setUploadReasonId(id);
        setOtherReason("");
    }, []);

    const { value: paperworkTypes } =
        useFilteredPaperworkTypes(complianceTypeId);
    const paperworkTypeOptions = useMemo<ISelectOption[]>(() => {
        return paperworkTypes.map((type) => ({
            label: t(type.paperworkType),
            value: type.id.toString(),
        }));
    }, [paperworkTypes, t]);

    const [paperworkType, setPaperworkType] = useState("");
    const [paperworkTypeId, setPaperworkTypeId] = useState("");
    const [otherPaperworkName, setOtherPaperworkName] = useState("");
    const updatePaperworkTypeId = useCallback(
        (id: string) => {
            setPaperworkTypeId(id);
            setOtherPaperworkName("");
            const type = paperworkTypes.find((o) => o.id.toString() === id);

            if (type !== undefined) {
                setPaperworkType(type.shortName);
            }
        },
        [paperworkTypes],
    );

    const [filename, setFilename] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const handleFileSelected = useCallback(
        (newFilename: string, newFile: File | null) => {
            setFilename(newFilename);
            setFile(newFile);
        },
        [],
    );

    const jobTypes = useMemo<ISelectOption[]>(
        () =>
            [
                "Repair",
                "Service",
                "Install",
                "Void Cap",
                "Void Maintenance",
                "Occupied Cap",
                "Occupied Maintenance",
                "Turn on and Test",
                "Other",
            ].map((j) => ({
                label: t(j),
                value: j,
            })),
        [t],
    );

    const getPaperworkId = useCallback(
        (name: string) =>
            paperworkTypes
                ?.find((type) => type.paperworkType === name)
                ?.id.toString(),
        [paperworkTypes],
    );

    const otherPaperworkTypeId = useMemo(
        () => getPaperworkId("Other"),
        [getPaperworkId],
    );

    const nonePaperworkTypeId = useMemo(
        () => getPaperworkId("None"),
        [getPaperworkId],
    );

    const isLgsrJob = useMemo(
        () =>
            paperworkTypeId === getPaperworkId("LGSR") ||
            paperworkTypeId === getPaperworkId("GSR"),
        [getPaperworkId, paperworkTypeId],
    );

    //#region validation
    const propertyIdValidator = useValidateField(propertyId, isRequired());
    const engineerIdValidator = useValidateField(engineerId, isRequired());
    const jobTypeValidator = useValidateField(jobType, isRequired());
    const uploadReasonIdValidator = useValidateField(
        uploadReasonId,
        isRequired(),
    );
    const otherReasonValidator = useValidateField(otherReason, isRequired());
    const acceptTermsValidator = useValidateField(acceptTerms, isTrue());
    const fileValidator = useValidateField(file, isRequired());
    const complianceTypeValidator = useValidateField(
        complianceTypeId,
        isRequired(),
    );
    const paperworkTypeIdValidator = useValidateField(
        paperworkTypeId,
        isRequired(),
    );
    const otherPaperworkNameValidator = useValidateField(
        otherPaperworkName,
        isRequired(),
    );

    const formValidation = validateForm(() => {
        const validators = [
            propertyIdValidator,
            engineerIdValidator,
            jobTypeValidator,
            acceptTermsValidator,
            complianceTypeValidator,
            uploadReasonIdValidator,
        ];

        if (uploadReasonId === otherReasonId) {
            validators.push(otherReasonValidator);
        }

        if (showPaperworkTypes) {
            validators.push(paperworkTypeIdValidator);

            if (paperworkTypeId !== nonePaperworkTypeId) {
                validators.push(fileValidator);
            }

            if (paperworkTypeId === otherPaperworkTypeId) {
                validators.push(otherPaperworkNameValidator);
            }
        }

        return validators;
    });
    //#endregion

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const handleSubmit = useCallback(() => {
        setShowSuccessMessage(false);

        onSubmit({
            engineerId: Number(engineerId),
            propertyId: Number(propertyId),
            fuelTypeId: Number(complianceTypeId),
            jobType: jobType,
            date: jobDate.toISOString(),
            manualUploadReasonId: Number(uploadReasonId),
            manualUploadOtherReason: otherReason,
            comments: comments,
            safeApplianceIds: safeApplianceIds,
            paperworkTypeId: Number(paperworkTypeId),
            otherPaperworkName: otherPaperworkName,
            resetNextServiceDate: resetServiceDate,
            isLgsrJob: isLgsrJob,
            filename: filename,
            file: file,
            landlordId: landlordId,
            paperworkType: paperworkType,
            propertyUprn: propertyUprn,
        }).subscribe(() => {
            updatePropertyId("");
            propertyIdValidator.reset();
            complianceTypeValidator.reset();

            setEngineerId("");
            engineerIdValidator.reset();

            setJobType("");
            jobTypeValidator.reset();

            setAcceptTerms(false);
            acceptTermsValidator.reset();

            handleFileSelected("", null);
            fileValidator.reset();

            updatePaperworkTypeId("");
            paperworkTypeIdValidator.reset();
            otherPaperworkNameValidator.reset();

            updateUploadReasonId("");
            uploadReasonIdValidator.reset();
            otherReasonValidator.reset();

            setSafeApplianceIds([]);
            setJobDate(getNow());
            setResetServiceDate(false);
            setComments("");

            setShowSuccessMessage(true);
        });
    }, [
        acceptTermsValidator,
        comments,
        complianceTypeId,
        complianceTypeValidator,
        engineerId,
        engineerIdValidator,
        file,
        fileValidator,
        filename,
        handleFileSelected,
        isLgsrJob,
        jobDate,
        jobType,
        jobTypeValidator,
        landlordId,
        onSubmit,
        otherPaperworkName,
        otherPaperworkNameValidator,
        otherReason,
        otherReasonValidator,
        paperworkType,
        paperworkTypeId,
        paperworkTypeIdValidator,
        propertyId,
        propertyIdValidator,
        propertyUprn,
        resetServiceDate,
        safeApplianceIds,
        updatePaperworkTypeId,
        updatePropertyId,
        updateUploadReasonId,
        uploadReasonId,
        uploadReasonIdValidator,
    ]);

    return propertiesLoaded && engineersLoaded ? (
        <Card title={cardTitle}>
            <Form onSubmit={handleSubmit} {...formValidation}>
                <SelectFilter
                    label={t("Property")}
                    value={propertyId}
                    onChange={updatePropertyId}
                    options={propertyOptions}
                    customRender={customPropertyRender}
                    applySearch={propertiesApplySearch}
                    search={propertiesSearch}
                    goToNextPage={propertiesGoToNextPage}
                    totalPages={propertiesTotalPages}
                    currentPage={propertiesCurrentPage}
                    {...propertyIdValidator}
                />

                {isServiceDue && (
                    <p className={styles.warningMessage}>
                        {t(
                            "Gas Service Date is overdue/due soon, you should schedule " +
                                "a Gas service for this property",
                        )}
                    </p>
                )}

                {isCapped && (
                    <p className={styles.warningMessage}>
                        {t("This property is currently capped")}
                    </p>
                )}

                {propertyId && (
                    <SearchableSelect
                        label={t("Compliance type")}
                        placeholder={t("Compliance type")}
                        options={complianceTypeOptions}
                        value={complianceTypeId}
                        onChange={setComplianceTypeId}
                        applySearch={setComplianceTypeFilter}
                        {...complianceTypeValidator}
                    />
                )}

                <SelectFilter
                    label={t("Engineer")}
                    value={engineerId}
                    onChange={setEngineerId}
                    options={enginnerOptions}
                    applySearch={engineersApplySearch}
                    currentPage={engineersCurrentPage}
                    goToNextPage={engineersGoToNextPage}
                    search={engineersSearch}
                    totalPages={engineersTotalPages}
                    {...engineerIdValidator}
                />

                <Select
                    label={t("Reason")}
                    value={uploadReasonId}
                    onChange={updateUploadReasonId}
                    options={manualUploadReasonsOptions}
                    allowEmpty={true}
                    {...uploadReasonIdValidator}
                />

                {uploadReasonId === otherReasonId && (
                    <InputField
                        label={t("Other reason")}
                        value={otherReason}
                        onChange={setOtherReason}
                        {...otherReasonValidator}
                    />
                )}

                <Select
                    label={t("Job type")}
                    options={jobTypes}
                    onChange={setJobType}
                    value={jobType}
                    allowEmpty={true}
                    {...jobTypeValidator}
                />

                <InputDatePicker
                    label={dateFieldLabel}
                    date={jobDate}
                    onDateSelected={setJobDate}
                />

                {showCommentsField && (
                    <MultilineInputField
                        label={t("Comments")}
                        value={comments}
                        onChange={setComments}
                    />
                )}

                {showPaperworkTypes ? (
                    <>
                        <Select
                            label={t("Select paperwork type")}
                            options={paperworkTypeOptions}
                            onChange={updatePaperworkTypeId}
                            value={paperworkTypeId}
                            allowEmpty={true}
                            {...paperworkTypeIdValidator}
                        />

                        {paperworkTypeId === otherPaperworkTypeId && (
                            <InputField
                                label={t("Paperwork name")}
                                value={otherPaperworkName}
                                onChange={setOtherPaperworkName}
                                {...otherPaperworkNameValidator}
                            />
                        )}

                        {paperworkTypeId &&
                            paperworkTypeId !== nonePaperworkTypeId && (
                                <>
                                    <Checkbox
                                        checked={resetServiceDate}
                                        onChange={setResetServiceDate}
                                    >
                                        {t(
                                            "Would you like to reset the property next service date upon submission?",
                                        )}
                                    </Checkbox>

                                    <FileUpload
                                        label={t("Paperwork")}
                                        maxFileSizeInMB={5}
                                        onFileSelected={handleFileSelected}
                                        allowedFileTypes={[".pdf"]}
                                        {...fileValidator}
                                    />
                                </>
                            )}
                    </>
                ) : (
                    <FileUpload
                        label={t("Unable to access evidence")}
                        maxFileSizeInMB={5}
                        onFileSelected={handleFileSelected}
                        allowedFileTypes={[".jpeg", ".jpg", ".png", ".pdf"]}
                    />
                )}

                {showUnsafeAppliances && propertyId && (
                    <UnsafeAppliances
                        propertyId={propertyId}
                        safeApplianceIds={safeApplianceIds}
                        assignSafeApplianceIds={setSafeApplianceIds}
                    />
                )}

                <Checkbox
                    checked={acceptTerms}
                    onChange={setAcceptTerms}
                    {...acceptTermsValidator}
                >
                    {t(
                        `I understand that this document was not created or uploaded by Plentific.
                        Its contents are the responsibility of the person who created it.
                        The details (other than the paperwork captured) in this form are my responsibility.`,
                    )}
                </Checkbox>

                {isLoading ? (
                    <Loading />
                ) : (
                    <Button
                        variant="primary"
                        type="submit"
                        cssRules={{ marginBottom: "10px" }}
                    >
                        {t("Upload")}
                    </Button>
                )}
                {error && <Alert type="error">{error}</Alert>}
                {showSuccessMessage && (
                    <Alert type="success">{successMessage}</Alert>
                )}
            </Form>
        </Card>
    ) : (
        <Loading />
    );
};

export default ManualUploadForm;
